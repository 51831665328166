<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>{{ $t("certificate_template.management") }}</li>
            <li>{{ $t("certificate_template.edit") }}</li>
          </ul>
        </nav>
      </div>

      <template v-if="errorMessages">
        <div
          v-for="(error, index) in errorMessages"
          :key="index"
          class="uk-alert-danger"
          uk-alert
        >
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>
      <div class="card">
        <form class="card-body">
          <div class="row">
            <div class="col-xl-12 m-auto">
              <div class="form-group row mb-3">
                <label class="col-md-3 col-form-label" for="course_title"
                  >{{ $t("certificate_template.name")
                  }}<span class="required">*</span></label
                >
                <div class="col-md-9">
                  <input
                    v-model="template.name"
                    type="text"
                    class="form-control"
                    id="course_title"
                    name="title"
                    :placeholder="$t('certificate_template.name')"
                    required=""
                  />
                </div>
              </div>
              <div class="form-group row mb-3">
                <label class="col-md-3 col-form-label" for="status">
                  {{ $t("general.status") }}</label
                >
                <div class="col-md-9">
                  <toggle-button
                    id="status"
                    v-if="updateStatus"
                    :width="80"
                    v-model="template.status_id"
                    color="#ae71ff"
                    :sync="true"
                    :labels="{
                      checked: $t('general.active'),
                      unchecked: $t('general.passive'),
                    }"
                  />
                </div>
              </div>
              <div class="form-group row mb-3">
                <label class="col-md-3 col-form-label" for="logo"
                  >{{ $t("certificate_template.image")
                  }}<span class="required">*</span></label
                >
                <div class="col-md-9">
                  <input
                    type="file"
                    class="d-none"
                    id="cover_photo_input"
                    accept="image/*"
                    @change="processFile($event)"
                  />
                  <button
                    @click="inputClick()"
                    type="button"
                    class="btn btn-default btn-icon-label"
                  >
                    <span class="btn-inner--icon">
                      <i class="uil-image"></i>
                    </span>
                    <span class="btn-inner--text">{{
                      $t("certificate_template.change")
                    }}</span>
                  </button>
                </div>
              </div>
              <div class="certificate-container position-relative mb-3">
                <div class="certificate template2">
                  <div class="water-mark-overlay"></div>
                  <div class="certificate-header">
                    <img
                      src="@/assets/images/anzera-logo-medium.png"
                      class="logo"
                      alt=""
                    />
                  </div>
                  <div class="certificate-body">
                    <p class="certificate-title">
                      <strong>{egitim.adi}</strong>
                    </p>
                    <h1>{{ $t("certificate.name") }}</h1>
                    <p class="student-name">
                      <span>Sayın</span>
                      {k.adi} {k.soyadi}
                    </p>
                    <div class="certificate-content">
                      <div class="about-certificate">
                        <p>
                          {{ $t("certificate.body_text_example") }}
                        </p>
                      </div>
                    </div>
                    <div class="certificate-footer">
                      <div class="row">
                        <div class="col-md-12 text-center">
                          <p>{{ $t("certificate.certifying_name") }}</p>
                          <span>{{ $t("certificate.certifying_title") }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  :src="template.cover_image_link"
                  class="certificate-background-img logoarea"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="uk-card-footer float-right pb-0 pr-0">
            <button
              class="uk-button uk-button-primary small"
              type="button"
              @click="saveForm()"
            >
              <i class="icon-feather-arrow-right"></i> {{ $t("general.save") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/core/services";
import moment from "moment";
import module, {
  BASE_URL,
  UPDATE_ITEM,
  ITEM,
  GET_ITEM,
  MODULE_NAME,
  ERROR,
} from "@/core/services/store/certificate_template.module";
export default {
  name: "CertificateTemplatesEdit",
  components: {},
  data() {
    return {
      successMessage: null,
      cover_image: null,
      errorMessages: [],
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
  },
  computed: {
     template: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEM];
      },
      set(value) {},
    },
    updateStatus(){
      if(this.template == null)return false
      this.template.status_id = (this.template.status_id ==  true)?true:false
      return true
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) {},
    },
  },
  methods: {
    saveForm() {
      let self = this.template;
      let formData = new FormData();
      this.errorMessages = [];
      if (self.name.length < 2) {
            this.scrollToTop();
        this.errorMessages.push(
          this.$t("validation.required_field").replace(
            ":NAME",
            this.$t("certificate_template.name")
          )
        );
        return false;
      }
      formData.append("name", self.name);
      if(this.cover_image != null)formData.append("cover_image", this.cover_image);
      formData.append("status_id", self.status_id == true ? "1" : "0");
      store
        .dispatch(MODULE_NAME + "/" + UPDATE_ITEM, {
          url: BASE_URL+"/update/"+this.$route.params.id,
          contents: formData,
        })
        .then(() => {
          this.scrollToTop();
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_created");
            this.$router.push({ name: "company.certificate_template.list" });
          }else this.errorMessages.push(this.error)
        });
    },
    getTemplateItem() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEM, {
        url: BASE_URL +"/"+ this.$route.params.id,
      });
    },
    processFile(event) {
      this.cover_image = event.target.files[0];
      let blob = URL.createObjectURL(this.cover_image);
      let imageHolder = $(".logoarea");
      imageHolder.attr("src", blob);
    },
    inputClick() {
      $("#cover_photo_input").click();
    },
    resetMessages() {
      this.errorMessages = [];
      this.successMessage = null;
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    this.getTemplateItem()
  },
  watch: {
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
.certificate-background-img {
  top: 0px;
  height: 100%;
  width: 100%;
  left: 0px;
  bottom: 0px;
  right: 0px;
  position: absolute;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}
.certificate.template2::after {
  background-image: unset !important;
  background-color: transparent !important;
}
.certificate.template2 {
  z-index: 101;
  position: relative;
}
</style>
